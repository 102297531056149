import React from "react";
import { graphql } from "gatsby";
import { withAuthenticator } from "aws-amplify-react";
import MyTheme from "../../../../../shared_ui/layouts/authTheme";

import Page from "./page";
import Errors from "../components/errors";

export const query = graphql`
  query ProtectedPageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      ...PageRouteData
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`;

const ProtectedPage = props => {
  const { data, errors } = props;
  if (errors) {
    return <Errors errors={errors} />;
  }
  return <Page data={data} />;
};

export default withAuthenticator(ProtectedPage, false, [], null, MyTheme);
